import React from 'react'
import Select, { components } from "react-select";
import { Scrollbars } from "react-custom-scrollbars";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";
const renderScrollbarCondition = (props) => {
  return (
    <div style={{ height: 100 }}>
      <Scrollbars>{props.children}</Scrollbars>
    </div>
  );
};

const renderOption = (props) => {
  return (
    <Option {...props}>
      <div>{props.data.label}</div>
    </Option>
  );
};
const { Option } = components;

const RV = (props) => {

  return (
    <>
      <div className="form-field-row two-col clearfix">
        <div className="form-field-col">
          <label> Stock Number (Optional)</label>
          <NumberFormat
            required
            className="form-control"
            onChange={props.handleOnChangeAssetDetail}
            value={props.stockNumber}
            name="stockNumber"
            onBlur={() =>
              props.handleOnBlurStock(
                props.stockNumber
              )
            }
          />
        </div>

        <div className="form-field-col selectSalutation">
          <label>Trade In</label>
          <Select
            placeholder="Select Trade In"
            id="selectedIn"
            name="tradeIn"
            value={props.selectedTradeIn}
            onChange={(e) =>
              props.changeSelect(
                e,
                "tradeIn",
                "selectedTradeIn"
              )
            }
            options={props.tradeIns}
            isSearchable
            isClearable
            className="react-select-main"
            classNamePrefix="react-select"
            components={{
              Option: renderOption,
              MenuList: renderScrollbarCondition,
            }}
            captureMenuScroll={false}
          />
        </div>
      </div>


      <div className="form-field-row two-col clearfix">
        <div className="form-field-col">
          <label> Year </label>
          <MaskedInput
            mask={[/[0-9]/i, /[0-9]/, /[0-9]/i, /[0-9]/]}
            className="form-control"
            guide={false}
            placeholder="Year"
            id="year"
            name="year"
            value={props.year}
            onChange={props.handleOnChangeAssetDetail}
          />
          {
            <span className='text-danger'>{props.errors_asset_detail_form.year}</span>
          }
        </div>
        <div className="form-field-col">
          <label> Make<em>*</em> </label>
          <input
            type="text"
            className="form-control"
            name="make"
            placeholder="Make"
            value={props.make}
            onChange={props.handleOnChangeAssetDetail}
          />
          {
            <span className='text-danger'>{props.errors_asset_detail_form.make}</span>
          }
        </div>
      </div>


      <div className="form-field-row two-col clearfix">
        <div className="form-field-col">
          <label> Model<em>*</em> </label>
          <input
            type="text"
            className="form-control"
            name="model"
            placeholder="Model"
            value={props.model}
            onChange={props.handleOnChangeAssetDetail}
          />
          {
            <span className='text-danger'>{props.errors_asset_detail_form.model}</span>
          }
        </div>
        <div className="form-field-col">
          <label> KM<em>*</em> </label>
          <NumberFormat
            className="form-control"
            value={props.kilometer}
            decimalScale={2}
            onChange={props.handleOnChangeAssetDetail}
            thousandSeparator={true}
            id="kilometer"
            name="kilometer"
            allowNegative={false}
          />
          {
            <span className='text-danger'>{props.errors_asset_detail_form.kilometer}</span>
          }
        </div>
      </div>

      <div className="form-field-row two-col clearfix">
        <div className="form-field-col">
          <label> Length/Size </label>
          <NumberFormat
            className="form-control"
            value={props.length}
            decimalScale={2}
            onChange={props.handleOnChangeAssetDetail}
            thousandSeparator={true}
            id="length"
            name="length"
            allowNegative={false}
          />
        </div>

        <div className="form-field-col">
          <label>VIN or Serial#<em>*</em></label>
          <input
            type="text"
            className="form-control"
            name="vin"
            placeholder="VIN or Serial#"
            value={props.vin}
            onChange={props.handleOnChangeAssetDetail}
            maxLength="17"
          />
          {
            <span className='text-danger'>{props.errors_asset_detail_form.vin}</span>
          }
        </div>
      </div>

      <div className="form-field-row two-col clearfix">
        <div className="form-field-col">
          <label> Price<em>*</em> </label>
          <NumberFormat
            className="form-control"
            value={props.price}
            decimalScale={2}
            onChange={props.handleOnChangeAssetDetail}
            thousandSeparator={true}
            prefix={"$"}
            id="price"
            name="price"
            allowNegative={false}
          />
          {
            <span className='text-danger'>{props.errors_asset_detail_form.price}</span>
          }
        </div>
        <div className="form-field-col selectSalutation">
          <label> Condition<em>*</em> </label>
          <Select
            id="selectedCondition"
            name="condition"
            value={props.selectedCondition}
            placeholder="Select Condition"
            onChange={(e) =>
              props.changeSelect(
                e,
                "condition",
                "selectedCondition"
              )
            }
            options={props.conditions}
            isSearchable
            isClearable
            className="react-select-main"
            classNamePrefix="react-select"
            components={{
              Option: renderOption,
              MenuList: renderScrollbarCondition,
            }}
            captureMenuScroll={false}
          />
          {
            <span className='text-danger'>{props.errors_asset_detail_form.condition}</span>
          }
        </div>
      </div>

    </>
  )
}
export default RV